import React from "react";
import useInterval from "../hooks/use-interval";

import * as style from "./call-count.module.css";

const CallCount = () => {
  const [value, setValue] = React.useState(210);
  useInterval(async () => {
    try {
      const response = await fetch(
        "https://call-a-colleague.eu/api/v1/calls/count"
      );
      const result = await response.json();
      if (result.ok) setValue(result.payload);
    } catch (e) {
      console.error(e);
    }
  }, 10000);
  return <span className={style.callCount}>{value}</span>;
};

export default CallCount;
