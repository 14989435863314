import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Navigation, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import * as style from "./testimonials.module.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function Testimonials({}) {
  const data = useStaticQuery(graphql`
    query AssetsPhotos {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          dir: { regex: "/images/testimonials/" }
        }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
        }
      }
    }
  `);

  const images = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.gatsbyImageData
  );

  return (
    <div className={style.wrapper}>
      <Swiper
        modules={[Navigation, Autoplay, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
        }}
        loop
        className={style.swiper}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <GatsbyImage image={image} alt={`Testimonial ${index + 1}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
