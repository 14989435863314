import React from "react";

import translate from "../../utils/translate";
import teamImage from "../../images/team01.png";

import * as style from "./team.module.css";

const STRINGS = {
  en: {
    we_built_it: "We built it ;)",
    paragraph_1:
      "Elshad is a Software Architect from Kharkiv, Ukraine. Olga is a Cultural Historian & Career Strategist.",
    paragraph_2: "We're married and based in Berlin, Germany.",
    read_more: "Read more",
  },
  ua: {
    we_built_it: "Ми це зробили ;)",
    paragraph_1:
      "Ельшад — Software Architect з Харкова, Україна. Ольга — історик культури та кар’єрний стратег.",
    paragraph_2: "Ми одружені та проживаємо в Берліні, Німеччина.",
    read_more: "Дізнатися більше",
  },
};

export default function TeamSection({ language = "en" }) {
  const t = translate(language, STRINGS);

  return (
    <section id="team" className={style.section}>
      <h2>{t("we_built_it")}</h2>
      <img src={teamImage} className={style.image}></img>
      <p>{t("paragraph_1")}</p>
      <p>{t("paragraph_2")}</p>

      <p>
        <a
          href="https://docs.google.com/document/d/1fu8xPx1kFoLG4eAd0akL2eoHt-CoIFjgUZBikDeuo7o/edit?usp=sharing"
          target="_blank"
          className="cac-button"
        >
          {t("read_more")}
        </a>
      </p>
    </section>
  );
}
