import React from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { StaticImage } from "gatsby-plugin-image";

import * as style from "./how-it-works.module.css";

import "swiper/css";
import "swiper/css/navigation";

export default function HowItWorksSection({ title, items }) {
  return (
    <section id="how-it-works" className={style.section}>
      <h2>{title}</h2>

      <StaticImage
        src="../../images/call-screen.jpg"
        alt="Mentor waiting for a call"
        loading="lazy"
        width={200}
        height={300}
      />

      <Swiper
        modules={[Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        className={style.swiper}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={style.stepContainer}>
              <h3>{item.title}</h3>
              <p>{item.text}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
